import React from "react";
import {useLocation} from "react-router-dom";

const ElementWrapper = (props: any) => {
  const location = useLocation();
  const Element = props.routeElement;

  return <Element location={location} {...props} />;
};

export default ElementWrapper;

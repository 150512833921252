import clearstream_bundle from "../i18n/clearstream.json";
import cetip_bundle from "../i18n/cetip.json";
import asx_bundle from "../i18n/asx.json";
import hqla_bundle from "../i18n/hqla.json";
import hsbc_bundle from "../i18n/hsbc.json";
import tmx_bundle from "../i18n/tmx.json";
import strate_bundle from "../i18n/strate.json";
import cfcl_bundle from "../i18n/cfcl.json";
import northerntrust_bundle from "../i18n/northerntrust.json";

import clearstream_background_1 from '../images/clearstream/clearstream_background_1.jpg'
import clearstream_background_2 from '../images/clearstream/clearstream_background_2.jpg'
import clearstream_background_3 from '../images/clearstream/clearstream_background_3.jpg'
import clearstream_background_4 from '../images/clearstream/clearstream_background_4.jpg'
import clearstream_background_5 from '../images/clearstream/clearstream_background_5.jpg'
import clearstream_background_6 from '../images/clearstream/clearstream_background_6.jpg'
import cetip_background_1 from '../images/cetip/cetip_background_1.jpg'
import asx_background_1 from '../images/asx/asx_background_1.jpg'
import asx_background_2 from '../images/asx/asx_background_2.jpg'
import hqla_background_1 from '../images/hqla/hqla_background_1.jpg'
import hqla_background_2 from '../images/hqla/hqla_background_2.jpg'
import hqla_background_3 from '../images/hqla/hqla_background_3.jpg'
import hqla_background_4 from '../images/hqla/hqla_background_4.jpg'
import hsbc_background_1 from '../images/hsbc/hsbc_background_1.jpg'
import tmx_background_1 from '../images/tmx/tmx_background_1.jpg'
import strate_background_1 from '../images/strate/strate_background_1.jpg'
import strate_background_2 from '../images/strate/strate_background_2.jpg'
import cfcl_background_1 from '../images/cfcl/cfcl_background_1.jpg'
import northerntrust_background_1 from '../images/northerntrust/northerntrust_background_1.jpg'

const clearstreamBackgrounds: Array<string> = [clearstream_background_1, clearstream_background_2, clearstream_background_3, clearstream_background_4, clearstream_background_5, clearstream_background_6];
const cetipBackgrounds: Array<string> = [cetip_background_1];
const asxBackgrounds: Array<string> = [asx_background_1, asx_background_2];
const hqlaBackgrounds: Array<string> = [hqla_background_1, hqla_background_2, hqla_background_3, hqla_background_4];
const hsbcBackgrounds: Array<string> = [hsbc_background_1];
const tmxBackgrounds: Array<string> = [tmx_background_1];
const strateBackgrounds: Array<string> = [strate_background_1, strate_background_2];
const cfclBackgrounds: Array<string> = [cfcl_background_1];
const northerntrustBackgrounds: Array<string> = [northerntrust_background_1];

export const backgrounds: { [key: string]: Array<string> } = {
  clearstream: clearstreamBackgrounds,
  cetip: cetipBackgrounds,
  asx: asxBackgrounds,
  hqla: hqlaBackgrounds,
  hsbc: hsbcBackgrounds,
  tmx: tmxBackgrounds,
  cfcl: cfclBackgrounds,
  strate: strateBackgrounds,
  northerntrust: northerntrustBackgrounds
};

export const bundles: { [key: string]: {} } = {
  clearstream: clearstream_bundle,
  cetip: cetip_bundle,
  asx: asx_bundle,
  hqla: hqla_bundle,
  hsbc: hsbc_bundle,
  tmx: tmx_bundle,
  cfcl: cfcl_bundle,
  strate: strate_bundle,
  northerntrust: northerntrust_bundle
};

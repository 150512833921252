import React, {useContext} from 'react';
import PartnerContext, {passwordIconForPartner} from "../../partner-context";

const PasswordIcon: React.FC = () => {
  const partner = useContext(PartnerContext);

  return (
    <img className="col-auto p-0 m-1" src={passwordIconForPartner.get(partner)} alt="Password Icon"/>
  );
};

export default PasswordIcon;

import React from 'react';
import indexedDbCertificateIcon from '../../images/common/certificate-icon-selected.png'
import Certificate from "../../models/Certificate";
import {FormattedMessage} from "react-intl";
import {DATETIME_FORMAT} from "../../utils/date-time-format";

interface CertificatesGroupTableRowProps {
  type: string,
  certificate: Certificate
}

const CertificatesGroupEntry: React.FC<CertificatesGroupTableRowProps> = (props: CertificatesGroupTableRowProps) => {
  const certificate = props.certificate;
  const formatDate = (date: string | number | undefined): string => date ? DATETIME_FORMAT.format(new Date(date)) : "";

  const renderCertificateDataRow = (i18nKey: string, value: any) =>
    <div className="row m-0">
      <label className="col-4 p-0 m-0">
        <FormattedMessage id={i18nKey}/>
      </label>
      <div className="col-8 p-0 pl-2">{value}</div>
    </div>;

  return (
    <div className="row m-0 p-1 align-items-center">
      <div className="col-2 p-0 text-center">
        <img src={indexedDbCertificateIcon} alt="certificate"/>
        <div>{props.type}</div>
      </div>
      <div className="col-10 p-0">
        {renderCertificateDataRow('support.page.manage.certificates.common.name', certificate.subject?.CN)}
        {renderCertificateDataRow(
          'support.page.manage.certificates.subject.alternative.name',
          certificate.subjectAlternativeName || "/"
        )}
        {renderCertificateDataRow('support.page.manage.certificates.serial.number', certificate.serialNumber)}
        {renderCertificateDataRow(
          'support.page.manage.certificates.start.expiry.date',
          formatDate(certificate.validFrom) + " | " + formatDate(certificate.validTo))}
        {renderCertificateDataRow(
          'support.page.manage.certificates.last.used.last.modified.created.on',
          formatDate(certificate.lastUsed) + " | " + formatDate(certificate.lastModified) + " | " + formatDate(certificate.createdOn)
        )}
      </div>
    </div>
  );
};

export default CertificatesGroupEntry;

import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import '../css/components/LandingPage.css';
import PartnerContext, {Partner} from "../partner-context";
import BoxBody from "../components/common/BoxBody";
import {FormattedMessage} from "react-intl";
import BoxFooter from "../components/common/BoxFooter";
import Box from "../components/common/Box";

const LandingPage: React.FC = () => {
  const partner = useContext(PartnerContext);
  const isClearstream: boolean = partner === Partner.Clearstream;
  const isCfcl: boolean = partner === Partner.Cfcl;
  return (
    <Box>
      <BoxBody>
        <div className="container p-0">
          <div className="row m-0 pt-4">
            <Link to="/login" className="col-auto p-0">
              <button id="landing-page-login-button" className={`xact-button ${partner} big`} autoFocus={true}>
                <FormattedMessage id="landing.page.login.button"/>
              </button>
            </Link>
            <div className="col p-0 pl-4">
              <FormattedMessage id="landing.page.login.button.label"/>
            </div>
          </div>
          <div className="row m-0 pt-4 mt-2">
            <Link to="/support" className="col-auto p-0">
              <button className={`xact-button ${partner} big`}>
                <FormattedMessage id="landing.page.support.button"/>
              </button>
            </Link>
            <div className="col p-0 pl-4">
              <FormattedMessage id="landing.page.support.button.label"/>
            </div>
          </div>
        </div>
      </BoxBody>
      <BoxFooter>
        <div className="dropdown">
          <span className="dropdown-link">Documentation</span>
          <div className="dropdown-content shadow">
            {isClearstream &&
              <>
                <a className="dropdown-item"
                   href="https://www.clearstream.com/clearstream-en/securities-services/connectivity-1-/clearstreamxact/xactwebportal">
                  Xact Documentation
                </a>
                <a className="dropdown-item"
                   href="https://api.clearstream.com">
                  Clearstream API Platform
                </a>
                <a className="dropdown-item"
                   href="/privacy/privacy-notice.html">
                  Privacy notice
                </a>
              </>
            }
            {isCfcl &&
              <>
                <a className="dropdown-item"
                   href="https://www.clearstream.com/clearstream-en/keydocuments-1-/cfcl">
                  Documentation
                </a>
                <a className="dropdown-item"
                   href="/privacy/cfcl.html">
                  Privacy notice
                </a>
              </>
            }
          </div>
        </div>
      </BoxFooter>
    </Box>
  );
};

export default LandingPage;

export const download = (payload: string, filename: string, mimeType: string = 'text/plain') => {
  const blob = new Blob([payload], {type: mimeType});
  const nav = (window.navigator as any);
  if (nav.msSaveOrOpenBlob) {
    return nav.msSaveOrOpenBlob(blob, filename);
  }
  const downloadLink = document.createElement('a');
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.onclick = (event: MouseEvent) => document.body.removeChild(event.target as Node);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export default class OpenAmServerInfoEndpoint {
  public static call() {
    const url = `/authmanager/json/serverinfo/*`;

    return fetch(url, {
      method: 'GET',
    }).then((response: Response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response.statusText);
      }
    });
  }
}

import React, {useContext} from "react";
import {useIntl} from "react-intl";
import PartnerContext from "../../partner-context";
import {Link} from "react-router-dom";

const SupportPageButton: React.FC = () => {
  const partner = useContext(PartnerContext);
  const intl = useIntl();

  return (
    <Link
      to='/support'
      className={`xact-button ${partner} round-button`}
      title={intl.formatMessage({id: 'certificates.support page.tooltip'})}
    >
      ☰
    </Link>
  );
}

export default SupportPageButton;

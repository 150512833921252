import React from "react";
import BoxBody from "../common/BoxBody";
import BoxFooter from "../common/BoxFooter";
import XactFooterButton from "../common/XactFooterButton";
import {FormattedMessage} from "react-intl";

interface UpdateChoiceFormProps {
  titleI18NKey?: string;
  textI18NKey?: string;
  changeButtonI18NKey?: string;
  expiryDays?: string;
  updateEnforceDays?: string;
  handleUpdateChoiceTaken: (confirmChange: boolean) => void
}

function UpdateChoiceForm(props: UpdateChoiceFormProps) {
  return (
    <>
      <BoxBody>
        <h4><FormattedMessage id={props.titleI18NKey}/></h4>
        <p>
          <FormattedMessage
            id={props.textI18NKey}
            values={{expiryDays: props.expiryDays, updateEnforceDays: props.updateEnforceDays}}/>
        </p>
      </BoxBody>
      <BoxFooter>
        <XactFooterButton
          id="update-choice-change"
          onClick={() => props.handleUpdateChoiceTaken(true)}
          i18nKey={props.changeButtonI18NKey || "Update"}
        />
        <XactFooterButton
          id="update-choice-skip"
          onClick={() => props.handleUpdateChoiceTaken(false)}
          i18nKey="update.choice.proceed"
        />
      </BoxFooter>
    </>
  );
}

export default UpdateChoiceForm;

import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import PartnerContext from "../../partner-context";
import {Link} from "react-router-dom";
import {TroubleshootingKey} from "../../models/TroubleshootingKey";
import SupportRequest from "../../models/SupportRequest";

const GetAuthenticatorAppButton: React.FC = () => {
  const partner = useContext(PartnerContext);

  return (
    <Link
      to={'/support'}
      className={`xact-button ${partner} pl-2 pr-2`}
      state={{
        supportRequest: SupportRequest.Troubleshooting,
        troubleshootingAnchor: TroubleshootingKey.GET_AUTHENTICATOR_APP,
      }}
    >
      <FormattedMessage id="get.authenticator.app"/>
    </Link>
  );
}

export default GetAuthenticatorAppButton;

import React, {ChangeEvent} from 'react';
import newCertificateIcon from '../../images/common/certificate-addition.png'
import {FormattedMessage} from "react-intl";
import {MAX_FRIENDLY_NAME_LENGTH} from "../../utils/certificates";

interface NewCertificateEntryFormProps {
  filename: string;
  handleFriendlyNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  friendlyName?: string;
  password?: string;
  handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disablePasswordInput?: boolean;
}

const NewCertificateEntryForm = React.forwardRef<HTMLInputElement, NewCertificateEntryFormProps>((props, ref) => {
  return (
    <div className="certificate list-group-item list-group-item-action container border-bottom">
      <div className="row m-0 align-items-center">
        <div className="col-2 p-0 text-center">
          <img src={newCertificateIcon} alt="certificate"/>
        </div>
        <div className="col-10 p-0">
          <div className="row m-0">
            <label className="col-4 p-0">
              <FormattedMessage id="certificate.selector.new.certificate.p12.location.label"/>
            </label>
            <div className="col-8 p-0">{props.filename}</div>
            <label className="col-4 p-0">
              <FormattedMessage id="certificate.selector.new.certificate.password.label"/>
            </label>
            <input
              id="certificates-import-password"
              type="password"
              className="col-8 p-0"
              value={props.password}
              disabled={props.disablePasswordInput}
              onChange={props.handlePasswordChange}
              autoFocus={true}
            />
            <label className="col-4 p-0">
              <FormattedMessage id="certificate.selector.new.certificate.friendly.name.label"/>
            </label>
            <input
              id="certificates-import-friendly-name"
              value={props.friendlyName}
              type="text"
              className="col-8 p-0"
              onChange={props.handleFriendlyNameChange}
              disabled={!props.disablePasswordInput}
              maxLength={MAX_FRIENDLY_NAME_LENGTH}
              ref={ref}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewCertificateEntryForm;

import React, {useContext} from 'react';
import PartnerContext, {partnerLogo} from "../../partner-context";

const PageHeader: React.FC = () => {
  const partner = useContext(PartnerContext);

  return (
    <div id="page-header" className="fixed-top d-flex align-items-center p-4">
      <img src={partnerLogo.get(partner)} alt={partner + " Logo"}/>
    </div>
  );
};

export default PageHeader;

import React from 'react';
import BoxFooter from "../common/BoxFooter";
import Alert, {AlertType} from "../common/Alert";
import BoxBody from "../common/BoxBody";
import OpenAmMetaMessage from "../../openam/OpenAmMetaMessage";
import {useIntl} from 'react-intl';
import XactFooterButton from "../common/XactFooterButton";
import {Link} from "react-router-dom";

interface AlertBoxBodyProps {
  type: AlertType;
  text?: string;
  openAmMetaMessages?: Array<OpenAmMetaMessage> | null;
  remainingAttempts?: string | null;
  goToSupportPage?: boolean;
}

const AlertBoxBody: React.FC<AlertBoxBodyProps> = (props: AlertBoxBodyProps) => {
  const intl = useIntl();
  const defaultErrorMessage = 'Unexpected Error';
  const remainingAttempts: any = {remainingAttempts: props.remainingAttempts};

  const translatedErrorMessages = props.openAmMetaMessages
    ?.filter(message => message.i18nKey || message.providedI18N)
    .map(message => intl.formatMessage({id: message.i18nKey || message.providedI18N}, remainingAttempts))
    .join('. ');
  const goToLoginButton = (
    <XactFooterButton
      id="return-button"
      onClick={() => window.location.reload()}
      i18nKey="return.to.login.page"
      autoFocus={true}
    />
  );
  const goToSupportButton = (
    <Link to="/support">
      <XactFooterButton id="return-button" i18nKey="go.to.support.page" autoFocus={true}/>
    </Link>
  );

  return (
    <>
      <BoxBody>
        <Alert type={props.type} i18nKey={translatedErrorMessages || props.text || defaultErrorMessage}/>
      </BoxBody>
      <BoxFooter>
        {props.goToSupportPage ? goToSupportButton : goToLoginButton}
      </BoxFooter>
    </>
  );
};

export default AlertBoxBody;

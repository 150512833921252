import React from "react";
import Select from "react-select";
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import BoxBody from "../common/BoxBody";
import BoxFooter from "../common/BoxFooter";
import XactFooterButton from "../common/XactFooterButton";
import ImpersonationEntry from "../../models/ImpersonationEntry";
import {onEnterKeyPress} from "../../utils/enter-key-listener";
import ImpersonationSettings from "../../models/ImpersonationSettings";

interface ImpersonationModeFormProps {
  intl: IntlShape;
  settings: ImpersonationSettings;
  ouList: Array<ImpersonationEntry>;
  userList: Array<ImpersonationEntry>;
  handleImpersonationSkip: () => void;
  handleImpersonationStart: (settings: ImpersonationSettings, defaultUserId: string) => void;
  handleImpersonationSettingsChange: (settings: ImpersonationSettings) => void;
  handleGetAllUserListById: (defaultUserId: string) => void;
}

interface ImpersonationModeFormState {
  settings: ImpersonationSettings;
  ouInput: string;
  isMenuOpen: boolean;
}

class ImpersonationModeForm extends React.Component<ImpersonationModeFormProps, ImpersonationModeFormState> {
  static MILLISECONDS_TO_WAIT_FROM_LAST_KEY_UP: number = 1000;
  private typingTimeout: any = null;

  constructor(props: ImpersonationModeFormProps) {
    super(props);
    this.state = {
      settings: props.settings,
      ouInput: "",
      isMenuOpen: false,
    };
  }

  componentDidMount() {
    const currentOu = this.props.ouList.find((ou) => ou.id === this.state.settings.ouId);
    const defaultUserId = this.props.userList?.find((id) => id);
    if (currentOu === undefined) {
      this.setState((previousState) => {
        const currentSettings = previousState.settings;
        currentSettings.ouId = "";
        currentSettings.userId = "";
        currentSettings.userName = "";
        return {settings: currentSettings} as ImpersonationModeFormState;
      });
    } else {
      const isOpen = this.props.userList?.length > 1 ? true : false;
      this.setState((previousState) => {
        const currentSettings = previousState.settings;
        currentSettings.userId = defaultUserId?.id || "";
        currentSettings.userName = defaultUserId?.name || "";
        return {
          ouInput: currentOu.name,
          settings: currentSettings,
          isMenuOpen: isOpen,
        };
      });
    }
  }

  handleResourceChange = (event: any) => {
    const resourceId = event.target.value;
    this.setState((previousState) => {
      const currentSettings = previousState.settings;
      currentSettings.resourceId = resourceId;
      currentSettings.ouId = "";
      currentSettings.userId = "";
      currentSettings.userName = "";
      return {settings: currentSettings} as ImpersonationModeFormState;
    });
  };

  handleResourceSelected = () => {
    this.props.handleImpersonationSettingsChange(this.state.settings);
  };

  handleOuChange = (event: any) => {
    const ouName = event.target.value;
    const ou = this.props.ouList.find((ou) => ou.name === ouName);
    if (!ou) {
      this.setState((previousState) => {
        const currentSettings = previousState.settings;
        currentSettings.ouId = " ";
        currentSettings.userId = "";
        currentSettings.userName = "";
        return {
          settings: currentSettings,
          ouInput: ouName,
        } as ImpersonationModeFormState;
      });
      return;
    }

    const defaultUser = this.props.userList.find((user) => user.id);

    this.setState(
      (previousState) => {
        const currentSettings = previousState.settings;
        currentSettings.ouId = ou.id;
        currentSettings.userId = defaultUser?.id || "";
        currentSettings.userName = defaultUser?.name || "";
        return {settings: currentSettings} as ImpersonationModeFormState;
      },
      () => this.props.handleImpersonationSettingsChange(this.state.settings)
    );
  };

  handleUserChange = (selectedOption: any) => {
    const userId = selectedOption.value;
    const userName = selectedOption.label;
    const user = this.props.userList.find((user) => user.id === userId);
    if (!user) {
      this.setState((previousState) => {
        const currentSettings = previousState.settings;
        currentSettings.userId = "";
        currentSettings.userName = "";
        return {settings: currentSettings} as ImpersonationModeFormState;
      });
      return;
    }
    this.setState((previousState) => {
      const currentSettings = previousState.settings;
      currentSettings.userId = userId;
      currentSettings.userName = userName;
      return {settings: currentSettings} as ImpersonationModeFormState;
    });
  };

  isImpersonationUserSelected = () => this.state.settings.userId && this.state.settings.ouId;

  handleStartImpersonation = (defaultUserId: string) => {
    if (this.isImpersonationUserSelected()) {
      this.props.handleImpersonationStart(this.state.settings, defaultUserId);
    }
  };

  onKeyUpEvent = () => {
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.handleResourceSelected();
    }, ImpersonationModeForm.MILLISECONDS_TO_WAIT_FROM_LAST_KEY_UP);
  };

  render() {
    return (
      <>
        <BoxBody>
          <h4>
            <FormattedMessage id="impersonation.title"/>
          </h4>
          <label className="m-0" htmlFor="resource">
            <FormattedMessage id="impersonation.resource.label"/>
          </label>
          <input
            type="text"
            className="input-field"
            id="resource"
            onChange={this.handleResourceChange}
            onKeyUp={(_) => this.onKeyUpEvent()}
            onKeyPress={(event) => {
              clearTimeout(this.typingTimeout);
              onEnterKeyPress(event, this.handleResourceSelected);
            }}
            value={this.state.settings.resourceId}
          />
          <label className="m-0" htmlFor="ou">
            <FormattedMessage id="impersonation.ou.name.label"/>
          </label>
          <input
            id="ou_name"
            type="text"
            className="input-field"
            list="availableOUs"
            autoComplete="off"
            placeholder="Please select ..."
            value={this.state.ouInput}
            onChange={this.handleOuChange}
          />
          <datalist className="input-field" id="availableOUs">
            {this.props.ouList.map((ou) => (
              <option key={ou.id} value={ou.name}>
                {ou.name}
              </option>
            ))}
          </datalist>
          <label
            className="m-0"
            htmlFor="user"
            title={this.props.intl.formatMessage({
              id: "impersonation.user.select.info",
              defaultMessage: "",
            })}
          >
            <FormattedMessage id="impersonation.user.name.label"/>
          </label>
          <Select
            id="user"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                fontWeight: "normal",
                minHeight: "30px",
                height: "30px",
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: "#333",
                fontWeight: "normal",
                ":active": {
                  ...baseStyles[":active"],
                  backgroundColor: !state.isDisabled ? (state.isSelected ? "#fff" : "#8BA556") : undefined,
                  color: "#fff",
                },
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                color: "#333",
                fontWeight: "normal",
                paddingBottom: "4px",
              }),
              container: (baseStyles) => ({
                ...baseStyles,
                minHeight: 28,
                fontSize: 15,
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                fontWeight: "normal",
                minHeight: "30px",
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              indicatorsContainer: (baseStyles) => ({
                ...baseStyles,
                height: "30px",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "#8BA556",
                primary: "#79b51c66",
              },
            })}
            isDisabled={!(this.props.userList.length > 0)}
            onChange={this.handleUserChange}
            options={this.props.userList.map((user) => {
              return {value: user.id, label: user.name};
            })}
            isMulti={false}
            value={{
              value: this.state.settings.userId,
              label: this.state.settings.userName,
            }}
            onMenuClose={() => this.setState({isMenuOpen: false} as ImpersonationModeFormState)}
            menuIsOpen={this.props.userList.length > 0 && this.state.isMenuOpen}
            onMenuOpen={() => {
              this.setState({isMenuOpen: true} as ImpersonationModeFormState);
              if (this.props.userList.length <= 1) {
                this.props.handleGetAllUserListById(this.state.settings.userId);
              }
            }}
          />
        </BoxBody>
        <BoxFooter>
          <div className="col-auto mr-auto">
            <div className="row align-items-center">
              <XactFooterButton
                id="skip-impersonation-mode-button"
                onClick={this.props.handleImpersonationSkip}
                i18nKey="impersonation.skip"
                autoFocus={true}
              />
            </div>
          </div>
          <XactFooterButton
            id="start-impersonation-mode-button"
            onClick={() =>
              this.handleStartImpersonation(this.props.userList.length === 1 ? "" : this.state.settings.userId)
            }
            i18nKey="impersonation.start"
            disabled={!this.isImpersonationUserSelected()}
          />
        </BoxFooter>
      </>
    );
  }
}

export default injectIntl(ImpersonationModeForm);

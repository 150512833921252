import React, {useEffect, useRef} from "react";
import {FormattedMessage} from "react-intl";
import {TroubleshootingKey} from "../../models/TroubleshootingKey";

interface TroubleshootingMessageProps {
  i18nKey: TroubleshootingKey;
  isSelected?: boolean;
  children: (React.ReactElement | boolean)[];
}

const TroubleshootingMessage: React.FC<TroubleshootingMessageProps> = (props: TroubleshootingMessageProps) => {
  const divRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (props.isSelected) {
      divRef.current?.scrollIntoView();
    }
  });

  return (
    <div className={props.isSelected ? "bg-pink p-2" : "p-2"} ref={divRef} id={props.i18nKey}>
      <h4>
        <FormattedMessage id={props.i18nKey}/>
      </h4>
      <FormattedMessage
        id={props.i18nKey + ".text"}
        values={{
          b: (...msg) => <b>{msg}</b>,
          br: <br/>,
          ul: (...msg) => <ul>{msg}</ul>,
          li: (...msg) => <li>{msg}</li>,
          googleUrl: (
            <a
              className="link"
              href="https://play.google.com/store/apps/details?id=com.forgerock.authenticator"
              target="_top"
            >
              Google Play
            </a>
          ),
          iOSUrl: (
            <a className="link" href="https://apps.apple.com/app/forgerock-authenticator/id1038442926" target="_top">
              App Store
            </a>
          ),
        }}
      />
      {props.children}
    </div>
  );
};

export default TroubleshootingMessage;

import {TroubleshootingKey} from "./TroubleshootingKey";

export enum SupportPageCheckResult {
  RUNNING = "RUNNING",
  INFO = "INFO",
  WARNING = "WARNING",
  OK = "OK",
  FAILURE = "FAILURE",
}

export default interface SupportPageCheck {
  id: number;
  labelI18nKey: string;
  labelI18nParameters?: any;
  isSeparator?: boolean;
  result?: SupportPageCheckResult;
  resolutionHintI18nKey?: string;
  troubleShootingAnchor?: TroubleshootingKey;
}

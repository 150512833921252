import React, {useContext} from 'react';
import PartnerContext, {userIconForPartner} from "../../partner-context";

export const UserIcon: React.FC = () => {
  const partner = useContext(PartnerContext);
  return (
    <img className="col-auto p-0 m-1" src={userIconForPartner.get(partner)} alt="User Icon"/>
  );
};

export default UserIcon;

enum TreeNode {
  Credentials = "XactCredentialsNode",
  ChallengeResponse = "XactChallengeResponseNode",
  PasswordUpdate = "XactPasswordUpdateNode",
  CertificatesUpdate = "XactCertificatesUpdateNode",
  CertificateStorage = "XactCertificatesStorageNode",
  Impersonation = "XactImpersonationNode",
  LoginStatusDecision = "XactLoginStatusDecisionNode",
  OathRegistration = "OathRegistrationNode",
  OathTokenVerifier = "OathTokenVerifierNode",
  EmailOtp = "EmailOtp",
  Error = "XactErrorNode"
}

export default TreeNode;

import React from 'react';
import BoxFooter from "../common/BoxFooter";
import Alert, {AlertType} from "../common/Alert";
import XactFooterButton from "../common/XactFooterButton";
import BoxBody from "../common/BoxBody";
import Certificate from "../../models/Certificate";
import {getRandomString, updateWebCryptoCertificates} from "../../utils/reactWebCryptoAdapter";
import {FormattedMessage} from "react-intl";
import '../../css/components/StoreCertificatesForm.css';
import {getDisplayName} from "../../utils/certificates";

interface StoreCertificatesFormProps {
  selectedCertificate: Certificate;
  sslCertificate: string;
  signCertificate: string;
  caCertificate: string;
  handleCertificatesUpdateFinished: (confirmStorage: boolean) => void
}

interface StoreCertificatesFormState {
  certificatesHaveBeenStored: boolean;
  newP12BackupPassword: string;
  loginButtonEnabled: boolean;
  isCertificatesTableVisible: boolean;
}

class StoreCertificatesForm extends React.Component<StoreCertificatesFormProps, StoreCertificatesFormState> {
  private readonly showDetailsButton: React.RefObject<HTMLButtonElement>;
  private readonly toLoginPageButton: React.RefObject<HTMLButtonElement>;

  constructor(props: StoreCertificatesFormProps) {
    super(props);
    this.showDetailsButton = React.createRef();
    this.toLoginPageButton = React.createRef();
    this.state = {
      certificatesHaveBeenStored: false,
      newP12BackupPassword: getRandomString(),
      loginButtonEnabled: false,
      isCertificatesTableVisible: false,
    };

    this.certificateUpdateFinished = this.certificateUpdateFinished.bind(this);
    this.updateCertificates = this.updateCertificates.bind(this);
    this.renderStoringCompleted = this.renderStoringCompleted.bind(this);
    this.renderCertificateTable = this.renderCertificateTable.bind(this);
    this.showCertificatesTable = this.showCertificatesTable.bind(this);
    this.printCertificatesTable = this.printCertificatesTable.bind(this);
  }

  private certificateUpdateFinished() {
    this.props.handleCertificatesUpdateFinished(true);
  }

  componentDidMount() {
    this.showDetailsButton.current?.focus();
    this.updateCertificates()
      .then(_ => this.setState({certificatesHaveBeenStored: true}));
  }

  async updateCertificates() {
    await updateWebCryptoCertificates(
      this.props.selectedCertificate.friendlyName,
      this.props.sslCertificate,
      this.props.signCertificate,
      this.props.caCertificate,
      this.state.newP12BackupPassword
    );

    console.error("Invalid newCertificateStoreType");
  }

  render() {
    return this.state.certificatesHaveBeenStored ? this.renderStoringCompleted() : this.renderPleaseWait();
  }

  renderPleaseWait() {
    return (
      <BoxBody>
        <Alert type={AlertType.Warning} i18nKey="store.certificates.please.wait"/>
      </BoxBody>
    );
  }

  renderStoringCompleted() {
    return (
      <>
        <BoxBody>
          <Alert type={AlertType.Success} i18nKey="store.certificates.finished"/>
          {!this.state.loginButtonEnabled &&
            <p>
              <FormattedMessage id="store.certificate.take.note.P12.details" values={{
                u: (chucks) => <u>{chucks}</u>,
                b: (chucks) => <b>{chucks}</b>,
              }}
              />
            </p>
          }
          {this.renderCertificateTable()}
        </BoxBody>
        <BoxFooter>
          <XactFooterButton
            id="show-details-button"
            onClick={this.showCertificatesTable}
            i18nKey="store.certificates.show.details"
            ref={this.showDetailsButton}
          />
          <XactFooterButton
            id="print-details-button"
            onClick={this.printCertificatesTable}
            i18nKey="store.certificates.print.details"
          />
          <XactFooterButton
            id="submit-certificates-stored-button"
            onClick={this.certificateUpdateFinished}
            i18nKey="store.certificates.continue"
            disabled={!this.state.loginButtonEnabled}
            ref={this.toLoginPageButton}
          />
        </BoxFooter>
      </>
    );
  }

  renderCertificateTable() {
    const certificateName = getDisplayName(this.props.selectedCertificate);

    return (
      <div id="print-container" className={this.state.isCertificatesTableVisible ? "visible" : "hidden"}>
        <div id="section-to-print">
          <FormattedMessage id="store.certificates.keep.p12.information.private" values={{
            br: <br/>
          }}/>
          <table id="certificates-table">
            <tbody>
            <tr>
              <td><FormattedMessage id="store.certificates.certificate.friendly.name"/></td>
              <td>{certificateName}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="store.certificates.certificate.generation.date"/></td>
              <td>{new Date().toISOString()}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="store.certificates.p12.filename"/></td>
              <td>{certificateName + ".p12"}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="store.certificates.keep.p12.password"/></td>
              <td>{this.state.newP12BackupPassword}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>);
  }

  showCertificatesTable() {
    this.setState(
      {loginButtonEnabled: true, isCertificatesTableVisible: true},
      () => this.toLoginPageButton.current?.focus()
    );
  }

  printCertificatesTable() {
    this.setState(
      {loginButtonEnabled: true},
      () => window.print()
    );
  }
}

export default StoreCertificatesForm;

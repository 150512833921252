import React from "react";
import {FormattedMessage} from "react-intl";
import SupportPageCheck from "../../models/SupportPageCheck";
import {TroubleshootingKey} from "../../models/TroubleshootingKey";
import ChecklistNodeButton from "./ChecklistNodeButton";

interface ChecklistNodeProps {
  check: SupportPageCheck;
  onMoreInformationClicked: (troubleShootingAnchor: TroubleshootingKey) => void;
}

const ChecklistNode: React.FC<ChecklistNodeProps> = (props: ChecklistNodeProps) => {
  const check = props.check;
  const isChild = !Number.isInteger(check.id);
  return (
    <>
      <span className={isChild ? "child-check" : "check"}>{isChild ? "▻ " : check.id + ". "}</span>
      <FormattedMessage id={check.labelI18nKey} values={check.labelI18nParameters}/>...
      {!check.isSeparator && <span className={check.result}>[{check.result}]</span>}
      {check.resolutionHintI18nKey &&
        <>
          <span className="ml-1 mr-1">&rArr;</span>
          <FormattedMessage id={check.resolutionHintI18nKey} values={check.labelI18nParameters}/>
        </>
      }
      {check.troubleShootingAnchor &&
        <ChecklistNodeButton
          onClick={() => check.troubleShootingAnchor && props.onMoreInformationClicked(check.troubleShootingAnchor)}
          i18nKey={"support.page.checks.more.information.button"}
          i18nValues={check.labelI18nParameters}
        />
      }
      <br/>
    </>
  );
};

export default ChecklistNode;

import OpenAmMessage from "./OpenAmMessage";

export default class OpenAmAuthenticateEndpoint {
  public static call(realm: String | null, openAmMessage: OpenAmMessage | null, gotoParam: string | undefined) {
    // The below switches from "http" to "https" solve SAML2 redirect issues, but we do not know why..
    // Since the ServiceMesh migration, OpenAM internally runs on plaintext (http) which explains why the goto URL that
    // comes back is now http://<OCP-OPEMAM-ROUTE:80/...
    // However when doing SAML redirect over this route, the successUrl returned by OpenAM is "/portal" instead of the correct SAML goto URL.
    // By switching the gotoUrl to https and the default https port the successUrl becomes correct.
    // We suspect that the BaseUrlService (BaseURLProvider.class) of OpenAM has something to do with this and that this is linked to some X-Forwarded-XXX headers.
    // It could also be a bug.
    // However, at this time we have no proof for any of this, and we need to deliver an EPR for the June release ASAP.
    // In the absence of a better solution we are going for this "solution".
    gotoParam = gotoParam?.replace("http://", "https://");
    gotoParam = gotoParam?.replace(":80/", ":443/");

    // If no goto URL is specified, OpenAM will redirect to the default successUrl which is /portal
    const goto: string = gotoParam ? `&goto=${encodeURIComponent(gotoParam)}` : "";
    const url = `/authmanager/json/realms/root/realms/${realm}/authenticate?authIndexType=service&authIndexValue=loginServiceTree${goto}`;
    const body: string | null = openAmMessage === null ? null : JSON.stringify(openAmMessage);

    return fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'curl',
        'Content-Type': 'application/json'
      },
      body: body
    }).then(async (response: Response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 400) {
        throw new Error("error.bad.request.timed.out");
      }
      let error = await response.text() || response.statusText;
      try {
        const json = JSON.parse(error);
        if (json && typeof json === "object" && json.message) {
          error = json.message;
        }
      } catch (e) {
      }
      if (response.status >= 500) {
        error = 'The was a problem on the login server: ' + error;
      }
      throw new Error(error);
    });
  }
}

import React from 'react';
import XactButton, {XactButtonProps} from "./XactButton";

const XactFooterButton = React.forwardRef<HTMLButtonElement, XactButtonProps>((props: XactButtonProps, ref) => {
  return (
    <div className="col-auto p-0 pl-2">
      <XactButton {...props} ref={ref}/>
    </div>
  );
});

export default XactFooterButton;


export default class OpenAmSessionInfoEndpoint {
  public static hasUserValidSession() {
    const url = `authmanager/json/sessions?_action=getSessionInfo`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept-API-Version': 'protocol=1.0,resource=2.0'
      },
    }).then((response: Response) => {
      return response.ok;
    });
  }
}

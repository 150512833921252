import React from 'react';
import {Link, Location} from "react-router-dom";
import PartnerContext from "../partner-context";
import {FormattedMessage} from "react-intl";
import BoxBody from "../components/common/BoxBody";
import BoxFooter from "../components/common/BoxFooter";
import XactFooterButton from "../components/common/XactFooterButton";
import '../css/support.css';
import Loader from "../components/common/Loader";
import SupportPageCheck from "../models/SupportPageCheck";
import Troubleshooting from "../components/support/Troubleshooting";
import ManageCertificates from "../components/support/ManageCertificates";
import Checklist from "../components/support/Checklist";
import {TroubleshootingKey} from "../models/TroubleshootingKey";
import Box from "../components/common/Box";
import {getWebCryptoSecurity} from "../utils/global-variables";
import SupportRequest from "../models/SupportRequest";

interface SupportPageProps {
  location?: Location
}

interface SupportPageState {
  checklistChecks: Array<SupportPageCheck>;
  isLoading: boolean;
  supportRequest: SupportRequest;
  troubleshootingAnchor?: TroubleshootingKey;
}

class SupportPage extends React.Component<SupportPageProps, SupportPageState> {
  constructor(props: SupportPageProps) {
    super(props);
    // @ts-ignore
    const routeSupportRequest = props.location?.state?.supportRequest;
    // @ts-ignore
    const routeTroubleshootingAnchor = props.location?.state?.troubleshootingAnchor;
    this.state = {
      checklistChecks: [],
      isLoading: true,
      supportRequest: routeSupportRequest ? routeSupportRequest : SupportRequest.None,
      troubleshootingAnchor: routeTroubleshootingAnchor,
    };
  }

  async componentDidMount() {
    try {
      await getWebCryptoSecurity().initialize();
    } catch (exception) {
      console.error(exception);
    }
    this.setState({isLoading: false} as SupportPageState);
  }

  handleManageCertificatesClick = () =>
    this.setState({supportRequest: SupportRequest.ManageCertificates} as SupportPageState);


  handleCheckListClick = () =>
    this.setState({supportRequest: SupportRequest.BrowserChecklist} as SupportPageState);

  renderSupportContent = () => {
    if (this.state.isLoading) {
      return <Loader/>;
    }
    if (this.state.supportRequest === SupportRequest.BrowserChecklist) {
      return <Checklist onMoreInformationClicked={this.onMoreInformationClicked}/>
    }
    if (this.state.supportRequest === SupportRequest.ManageCertificates) {
      return <ManageCertificates/>
    }
    if (this.state.supportRequest === SupportRequest.Troubleshooting) {
      return <Troubleshooting anchor={this.state.troubleshootingAnchor}/>;
    }
    return <FormattedMessage id="support.page.select.request.type"/>;
  };

  renderFooter = () => {
    return (
      <BoxFooter>
        <XactFooterButton
          id="browser-checklist-button"
          onClick={this.handleCheckListClick}
          i18nKey="support.page.button.browser.checklist"
        />
        <XactFooterButton
          id="manage-certificates-button"
          onClick={this.handleManageCertificatesClick}
          i18nKey="support.page.button.manage.certificates"
        />
        <XactFooterButton
          id="troubleshooting-button"
          onClick={() => this.setState({supportRequest: SupportRequest.Troubleshooting} as SupportPageState)}
          i18nKey="support.page.button.troubleshooting"
        />
        <Link to="/">
          <XactFooterButton id="return-button" i18nKey="support.page.button.return"/>
        </Link>
      </BoxFooter>
    );
  };

  render() {
    return (
      <Box isSupportPage={true}>
        <BoxBody>
          <FormattedMessage id="support.page.description"/>
          <br/>
          <div id="support-container"
               className={this.state.supportRequest !== SupportRequest.None ? "active" : ""}>
            {this.renderSupportContent()}
          </div>
        </BoxBody>
        {this.renderFooter()}
      </Box>
    );
  }

  onMoreInformationClicked = (troubleShootingAnchor: TroubleshootingKey) => {
    this.setState({
      checklistChecks: [],
      isLoading: false,
      supportRequest: SupportRequest.Troubleshooting,
      troubleshootingAnchor: troubleShootingAnchor,
    });
  }
}

SupportPage.contextType = PartnerContext;

export default SupportPage;

import React, {ReactNode} from "react";
import CertificateSelector from "../components/login/CertificateSelector";
import PasswordForm from "../components/login/PasswordForm";
import Certificate from "../models/Certificate";
import Loader from "../components/common/Loader";
import OpenAmMessage, {ImpersonationChoice} from "../openam/OpenAmMessage";
import OpenAmAuthenticateEndpoint from "../openam/OpenAmAuthenticateEndpoint";
import TreeNode from "../openam/TreeNode";
import PasswordUpdateForm from "../components/login/PasswordUpdateForm";
import CertificatesUpdateForm from "../components/login/CertificatesUpdateForm";
import ImpersonationModeForm from "../components/login/ImpersonationModeForm";
import {AlertType} from "../components/common/Alert";
import AlertBoxBody from "../components/login/AlertBoxBody";
import StoreCertificatesForm from "../components/login/StoreCertificatesForm";
import {generateWebCryptoCSRs, sign} from "../utils/reactWebCryptoAdapter";
import UpdateChoiceForm from "../components/login/UpdateChoiceForm";
import Box from "../components/common/Box";
import ImpersonationSettings from "../models/ImpersonationSettings";
import OathRegistration from "../components/login/OathRegistration";
import OathTokenVerifier from "../components/login/OathTokenVerifier";
import EmailOtpVerifier from "../components/login/EmailOtpVerifier";
import {SIGN_CERT_SUFFIX} from "../utils/certificates";

interface LoginAppProps {
  realm: String;
  goto?: string;
}

interface LoginAppState {
  isLoading: boolean;
  selectedCertificate: Certificate;
  openAmMessage: OpenAmMessage | null;
  error?: string;
  impersonationSettings?: ImpersonationSettings;
}

class LoginApp extends React.Component<LoginAppProps, LoginAppState> {
  constructor(props: LoginAppProps) {
    super(props);
    this.state = {
      isLoading: false,
      selectedCertificate: {} as Certificate,
      openAmMessage: null,
    };

    this.sendCurrentOpenAmMessage = this.sendCurrentOpenAmMessage.bind(this);
    this.sendCredentialsMessage = this.sendCredentialsMessage.bind(this);
    this.handleCertificateSelection = this.handleCertificateSelection.bind(this);
    this.sendPasswordUpdateMessage = this.sendPasswordUpdateMessage.bind(this);
    this.handleCertificatesUpdate = this.handleCertificatesUpdate.bind(this);
    this.sendCertificatesStoredConfirmationMessage = this.sendCertificatesStoredConfirmationMessage.bind(this);

    this.startImpersonation = this.startImpersonation.bind(this);
    this.skipImpersonation = this.skipImpersonation.bind(this);
    this.sendUpdateChoiceConfirmationMessage = this.sendUpdateChoiceConfirmationMessage.bind(this);
  }

  handleCertificateSelection(selectedCertificate?: Certificate) {
    this.setState({selectedCertificate: selectedCertificate || {}} as LoginAppState, this.sendCurrentOpenAmMessage);
  }

  renderCertificateSelector() {
    return <CertificateSelector handleCertificateSelection={this.handleCertificateSelection}/>;
  }

  renderPasswordForm() {
    return (
      <PasswordForm
        commonName={this.state.selectedCertificate?.subject?.CN}
        subjectAlternativeName={this.state.selectedCertificate?.subjectAlternativeName}
        handlePasswordSubmit={this.sendCredentialsMessage}
      />
    );
  }

  renderCertificateUpdateForm() {
    return (
      <CertificatesUpdateForm
        selectedCertificate={this.state.selectedCertificate}
        handleCertificateUpdate={this.handleCertificatesUpdate}
        messages={this.state.openAmMessage?.getMessages()}
      />
    );
  }

  renderCertificateStorageForm() {
    return (
      <StoreCertificatesForm
        selectedCertificate={this.state.selectedCertificate}
        sslCertificate={this.state.openAmMessage!.getSslCertificate()}
        signCertificate={this.state.openAmMessage!.getSignCertificate()}
        caCertificate={this.state.openAmMessage!.getCaCertificate()}
        handleCertificatesUpdateFinished={this.sendCertificatesStoredConfirmationMessage}
      />
    );
  }

  renderImpersonationModeForm() {
    return (
      <ImpersonationModeForm
        settings={this.state.impersonationSettings || {resourceId: "", ouId: "", userId: "", userName: ""}}
        ouList={this.state.openAmMessage?.getOuList() || []}
        userList={this.state.openAmMessage?.getUserList() || []}
        handleImpersonationSkip={this.skipImpersonation}
        handleImpersonationStart={this.startImpersonation}
        handleImpersonationSettingsChange={this.handleImpersonationSettingsChange}
        handleGetAllUserListById={this.handleUserListImpersonationGet}
      />
    );
  }

  renderUpdateChoiceForm() {
    return (
      <UpdateChoiceForm
        titleI18NKey={this.state.openAmMessage?.getUpdateChoiceTitle()}
        textI18NKey={this.state.openAmMessage?.getUpdateChoiceText()}
        changeButtonI18NKey={this.state.openAmMessage?.getUpdateChoiceButton()}
        expiryDays={this.state.openAmMessage?.getUpdateChoiceExpiryDays()}
        updateEnforceDays={this.state.openAmMessage?.getUpdateChoiceUpdateEnforceDays()}
        handleUpdateChoiceTaken={this.sendUpdateChoiceConfirmationMessage}
      />
    );
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({openAmMessage: null, error: error.message} as LoginAppState);
  }

  sendCurrentOpenAmMessage() {
    OpenAmAuthenticateEndpoint.call(this.props.realm, this.state.openAmMessage, this.props.goto)
      .then((openAmResponse: object) => {
        const openAmMessage: OpenAmMessage = new OpenAmMessage(openAmResponse);
        if (openAmMessage.successUrl) {
          // TODO JWA to be adapted once clarified with Cedric and/or we find a better solution and/or fixed in future OpenAM versions
          // Workaround as successUrl returned by OpenAM is incorrect (OCP Url returned as successUrl instead of Xact webserver, resulting in redirect issues)
          const params = new URLSearchParams(window.location.search);
          let spEntityID = params.get("spEntityID");
          if (spEntityID) {
            // If the spEntityID is in the URL search parameters than we can be sure that we are in a SAML scenario
            // If we are not in a SAML scenario, we will use the relative "/portal" successUrl returned by OpenAM without modifying it

            // First, fetch the base domain of our webserver from location href (browser)
            let xactUrl = window.location.href;
            let xactUrlParts = xactUrl.split("/");
            let xactBaseDomain = xactUrlParts[0] + "//" + xactUrlParts[2];

            // Second, fetch the base domain of OpenAM in OCP (not usable but needed for replacement in below redirect step)
            let ocpBasedSuccessUrl = openAmMessage.successUrl;
            let ocpBasedSuccessUrlParts = ocpBasedSuccessUrl.split("/");
            let ocpBaseDomain = ocpBasedSuccessUrlParts[0] + "//" + ocpBasedSuccessUrlParts[2];

            // Redirect to the correct modified successUrl: e.g. https://xact.clearstream.com/authmanager/saml2/continue/metaAlias/clearstream/ClearstreamIDP
            let newSuccessUrl = openAmMessage.successUrl.replace(ocpBaseDomain, xactBaseDomain);
            window.location.replace(newSuccessUrl);
            return;
          }

          // Default non SAML login on relative "/portal" successUrl
          window.location.replace(openAmMessage.successUrl);
          return;
        }
        this.setState({openAmMessage: openAmMessage, isLoading: false} as LoginAppState);
      })
      .catch((e: Error) => {
        this.setState({openAmMessage: null, error: e.message} as LoginAppState);
      });
  }

  sendCredentialsMessage(password: string, cn: string, san?: string) {
    this.setState((previousState) => {
      const currentMessage = previousState.openAmMessage;
      let selectedCertificate = previousState.selectedCertificate;
      currentMessage?.setPassword(password);
      currentMessage?.setCommonName(cn);
      currentMessage?.setSubjectAlternativeName(san || '');
      if (!selectedCertificate.subject) {
        selectedCertificate = {subject: {CN: cn}, friendlyName: cn + SIGN_CERT_SUFFIX} as Certificate;
        if (san) {
          selectedCertificate.subjectAlternativeName = san;
        }
      }
      return {openAmMessage: currentMessage, selectedCertificate: selectedCertificate, isLoading: true};
    }, this.sendCurrentOpenAmMessage);
  }

  signAndSendChallengeResponseMessage(): void {
    const challenge = this.state.openAmMessage!.getChallenge();
    this.setState({isLoading: true} as LoginAppState);
    sign(this.state.selectedCertificate, challenge)
      .then((response: string) => {
        this.setState((previousState) => {
          let currentMessage = previousState.openAmMessage;
          currentMessage?.setChallengeResponse(response);
          currentMessage?.setCertificateSerialNumber(
            parseInt("0x" + previousState.selectedCertificate.serialNumber).toString()
          );
          return {openAmMessage: currentMessage} as LoginAppState;
        }, this.sendCurrentOpenAmMessage);
      })
      .catch((reason) => this.setState({error: reason} as LoginAppState));
  }

  sendPasswordUpdateMessage(newPassword: string) {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setNewPassword(newPassword);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  }

  sendOathToken = (token: string) => {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setOathToken(token);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  };

  sendEmailOtp = (otp: string) => {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setEmailOtp(otp);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  };

  sendCertificatesStoredConfirmationMessage(confirmStorage: boolean) {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setCertificateStorageConfirmation(confirmStorage);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  }

  sendUpdateChoiceConfirmationMessage(confirmChange: boolean) {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setUpdateChoiceConfirmation(confirmChange);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  }

  async handleCertificatesUpdate() {
    this.setState((previousState) => {
      const selectedCertificate = previousState.selectedCertificate;
      return {
        selectedCertificate: selectedCertificate,
        isLoading: true,
      } as LoginAppState;
    });
    let certificateSigningRequests = await generateWebCryptoCSRs(this.state.selectedCertificate);
    this.setState((previousState) => {
      const currentMessage = previousState.openAmMessage;
      currentMessage?.setSslCSR(certificateSigningRequests.sslCSR);
      currentMessage?.setSignCSR(certificateSigningRequests.signCSR);
      return {openAmMessage: currentMessage} as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  }

  handleImpersonationSettingsChange = (settings: ImpersonationSettings) => {
    this.setState((previousState) => {
      const currentMessage = previousState.openAmMessage;
      currentMessage?.setResourceId(settings.resourceId);
      currentMessage?.setOuId(settings.ouId);
      currentMessage?.setUserId(settings.userId);
      return {openAmMessage: currentMessage, isLoading: true, impersonationSettings: settings} as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  };

  handleUserListImpersonationGet = (defaultUserId: string) => {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setResourceId(previousState.impersonationSettings?.resourceId || "");
      currentMessage?.setOuId(previousState.impersonationSettings?.ouId || "");
      currentMessage?.setUserId(previousState.impersonationSettings?.userId || "");
      currentMessage?.setDefaultUserId(defaultUserId);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  };

  skipImpersonation() {
    this.setState((previousState) => {
      let currentMessage = previousState.openAmMessage;
      currentMessage?.setImpersonationChoice(ImpersonationChoice.SKIP);
      return {
        openAmMessage: currentMessage,
        isLoading: true,
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  }

  startImpersonation(settings: ImpersonationSettings, defaultUserId: string) {
    this.setState((previousState) => {
      const currentMessage = previousState.openAmMessage;
      currentMessage?.setResourceId(settings.resourceId);
      currentMessage?.setOuId(settings.ouId);
      currentMessage?.setUserId(settings.userId);
      currentMessage?.setDefaultUserId(defaultUserId);
      currentMessage?.setImpersonationChoice(ImpersonationChoice.IMPERSONATE);
      return {
        openAmMessage: currentMessage,
        impersonationSettings: undefined,
        isLoading: true
      } as LoginAppState;
    }, this.sendCurrentOpenAmMessage);
  }

  getComponentFromOpenAmMessage(): ReactNode | Element {
    const openAmMessage = this.state.openAmMessage;
    if (!openAmMessage?.callbacks) {
      return this.renderCertificateSelector();
    }
    switch (openAmMessage.getTreeNode()) {
      case TreeNode.Credentials:
        return this.renderPasswordForm();
      case TreeNode.ChallengeResponse:
        this.signAndSendChallengeResponseMessage();
        return;
      case TreeNode.PasswordUpdate:
        return (
          <PasswordUpdateForm
            messages={openAmMessage?.getMessages() || null}
            handlePasswordUpdate={this.sendPasswordUpdateMessage}
          />
        );
      case TreeNode.CertificatesUpdate:
        return this.renderCertificateUpdateForm();
      case TreeNode.CertificateStorage:
        return this.renderCertificateStorageForm();
      case TreeNode.Impersonation:
        return this.renderImpersonationModeForm();
      case TreeNode.LoginStatusDecision:
        return this.renderUpdateChoiceForm();
      case TreeNode.Error:
        return (
          <AlertBoxBody
            type={AlertType.Error}
            openAmMetaMessages={openAmMessage?.getMessages()}
            remainingAttempts={openAmMessage?.getRemainingAttempts()}
          />
        );
      case TreeNode.OathRegistration:
        return (
          <OathRegistration
            message={this.state.openAmMessage?.getMfaDeviceRegistration()}
            handleOathRegistration={() => this.setState({isLoading: true} as LoginAppState, this.sendCurrentOpenAmMessage)}
          />
        );
      case TreeNode.OathTokenVerifier:
        return <OathTokenVerifier handleOathToken={this.sendOathToken}/>;
      case TreeNode.EmailOtp:
        return <EmailOtpVerifier handleEmailOtp={this.sendEmailOtp}/>;
      default:
        this.setState({error: "invalid state"} as LoginAppState);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Box>
          <AlertBoxBody type={AlertType.Error} text={this.state.error}/>
        </Box>
      );
    }
    if (this.state.isLoading) {
      return (
        <Box>
          <Loader/>
        </Box>
      );
    }
    return (
      <Box>
        <>{this.getComponentFromOpenAmMessage()}</>
      </Box>
    );
  }
}

export default LoginApp;

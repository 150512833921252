const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
const TIME_ZONE = 'Europe/Berlin';
const TIME_FORMAT_OPTIONS = {hour: '2-digit', minute: '2-digit'};
const DATETIME_FORMAT_OPTIONS = {...DATE_FORMAT_OPTIONS, ...TIME_FORMAT_OPTIONS};
const TIME_FORMAT_OPTIONS_WITH_TZ: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
  timeZone: TIME_ZONE
};
const DATE_TIME_LOCALE = 'en-DE';
export const DATE_FORMAT = new Intl.DateTimeFormat(DATE_TIME_LOCALE, DATE_FORMAT_OPTIONS); // dd-MM-yyyy
export const TIME_FORMAT_WITH_TZ = new Intl.DateTimeFormat(DATE_TIME_LOCALE, TIME_FORMAT_OPTIONS_WITH_TZ); // hh:mm CET, in IE: hh:mm
export const DATETIME_FORMAT = new Intl.DateTimeFormat(DATE_TIME_LOCALE, DATETIME_FORMAT_OPTIONS as Intl.DateTimeFormatOptions);
